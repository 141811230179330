import React from 'react'
import Analytics from '../../../Views/Analytics/Analytics'
import { compose } from 'recompose'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'

function IndexPage() {
    return (
        <>
            <SEO title="Analytics" />
            <AuthUserContext.Consumer>
                {authUser => (
                    <Analytics authUser={authUser} />
                )}
            </AuthUserContext.Consumer>
        </>
    )
}

const condition = authUser => !!authUser

export default compose(
    withAuthorization(condition),
)(IndexPage)
import React, { useEffect, useState } from 'react'
import { CustomChart } from '../../components/common'

function AnalyticsChart(props) {
    const { data } = props;
    const [Data, setData] = useState([])
    const [labels, setLabels] = useState([])
    useEffect(() => {
        if (data) {
            setData(data.data)
            setLabels(data.labels)
        }
        return () => {

        }
    }, [data])
    return (
        <div className="analytics-chart">
            <CustomChart data={Data} labels={labels} />
        </div>
    )
}

export default AnalyticsChart

export const calculateCounts = (viewsCountArray) => {
    return viewsCountArray.map(data => {
        return data.docs.filter(doc => {
            // const startDate = moment(dateRange[0]).startOf("day").toDate()
            // const check = doc.data().date.toDate()
            // const endDate = moment(dateRange[1]).endOf("day").toDate()
            // return check > startDate && check < endDate
            return true;
        }).length
    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
}
import React, { useEffect, useState } from 'react'
import { Loading } from '../../components/common';
import { withFirebase } from '../../components/Firebase';

function AnalyticsViewed(props) {
    const { firebase, authUser } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [totalPhotos, setTotalPhotos] = useState(0);
    const [allPhoto, setAllPhoto] = useState(null);
    useEffect(() => {
        if (firebase) {
            getAllPhoto();
        }
        return () => {

        }
    }, [firebase])


    const getAllPhoto = async () => {
        setIsLoading(true);
        let refPhoto = firebase.db.collection("/photos")
            .where("accountID", "==", authUser.uid);
        await refPhoto.onSnapshot((data) => {
            setTotalPhotos(data.size);
            let pics = [];
            let x = 0;
            data.forEach(async (doc) => {
                let refView = await firebase.db.collection("/photos/" + doc.id + "/views").get().then((data) => { x++; return data.size });
                if (refView > 0) {
                    pics = pics.concat({ id: doc.id, ...doc.data(), views: refView });
                }
                if (x === data.size) {
                    pics.sort((a, b) => a.views - b.views)
                    pics.reverse();
                    setAllPhoto(pics);
                    setIsLoading(false);
                };
            });
        })
    }
    return (
        <div className="ds-row view-wrapper">
            <div className="most-view-img">
                {allPhoto && allPhoto.length > 0 ?
                    allPhoto.map((photo, index) => {
                        if (index < 6) {
                            return (<div className="img" key={index}>
                                <img src={photo.photoURL} />
                                <div className="total-view">
                                    <i className="fa fa-eye" aria-hidden="true"></i>&nbsp;&nbsp; {photo.views}
                                </div>
                            </div>)
                        }
                    })
                    : null}
            </div>
        </div>
    )
}

export default withFirebase(AnalyticsViewed);

import React from 'react'
import { SocialCard } from '../../components/common';
function AnalyticsSocial(props) {
    const { TotalDownloads, fb, ig, tw } = props;
    return (
        <div className="ds-row analytics-row">
            <SocialCard
                title="TOTAL downloads"
                number={TotalDownloads}
                className="dw"
            />
            <SocialCard
                title="Facebook sHARE BUTTON CLICKS"
                className="fb"
                number={fb}
                icon={<i className="fa fa-facebook" aria-hidden="true"></i>} />
            <SocialCard
                title="instagram sHARE BUTTON CLICKS"
                className="ig"
                number={ig}
                icon={<i className="fa fa-instagram" aria-hidden="true"></i>} />
            <SocialCard
                title="Twitter sHARE BUTTON CLICKS"
                className="tw"
                number={tw}
                icon={<i className="fa fa-twitter" aria-hidden="true"></i>} />
        </div>
    )
}

export default AnalyticsSocial

import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Card, StatusCard } from '../../components/common'
const icon_leads = require("../../assets/images/svgs/icon_leads.svg");
const mail_check = require("../../assets/images/svgs/mail-check.svg");
const cellphone_line = require("../../assets/images/svgs/cellphone-line.svg");
const userFollowLine = require("../../assets/images/svgs/userFollowLine.svg");
const home = require("../../assets/images/svgs/home.svg");
const cake = require("../../assets/images/svgs/cake.svg");

function AnalyticsData(props) {
    const { leads, devicesData, downloadCSV } = props;
    const [totalDevices, setTotalDevices] = useState(0)
    const [devicesDataSet, setDevicesDataSet] = useState([{
        data: [0, 0],
        backgroundColor: [
            '#89D2DC',
            '#6564DB',
        ],
        hoverOffset: 4
    }])
    useEffect(() => {
        setDevicesDataSet([{
            data: [devicesData.desktop, devicesData.mobile],
            backgroundColor: [
                '#6564DB',
                '#89D2DC',
            ],
            hoverOffset: 4
        }])
        setTotalDevices(devicesData.desktop + devicesData.mobile)
        return () => {

        }
    }, [devicesData])

    return (
        <div className="ds-row analytics-row">
            <div className="analytics-data">
                <div className="ds-row analytics-row data-row">
                    <Card
                        title="TOTAL LEADS"
                        number={leads.totalLeads}
                        color={"var(--main-pink)"}
                        icon={icon_leads} />
                    <button className="pb-btn" onClick={downloadCSV}>Download CSV</button>
                </div>

                <div className="ds-row analytics-row status-data-row">
                    <StatusCard
                        icon={mail_check}
                        number={leads.emailAddresses}
                        title={"EMAIL ADDRESS"} />
                    <StatusCard
                        icon={cellphone_line}
                        number={leads.phoneNumbers}
                        title={"PHONE NUMBER"} />
                </div>

                <div className="ds-row analytics-row status-data-row">
                    <StatusCard
                        icon={userFollowLine}
                        number={leads.firstNames}
                        title={"FIRST NAME"} />
                    <StatusCard
                        icon={userFollowLine}
                        number={leads.lastNames}
                        title={"LAST NAME"} />
                </div>

                <div className="ds-row analytics-row status-data-row">
                    <StatusCard
                        icon={home}
                        number={leads.zipCodes}
                        title={"ZIP CODE"} />
                    <StatusCard
                        icon={cake}
                        number={leads.birthDates}
                        title={"BIRTH DATE"} />
                </div>

            </div>

            <div className="analytics-devices">
                <Doughnut
                    options={{
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                title: function (tooltipItem, data) {
                                    return data['labels'][tooltipItem[0]['index']];
                                },
                                label: function (tooltipItem, data) {
                                    return "";// data['datasets'][0]['data'][tooltipItem['index']];
                                },
                                afterLabel: function (tooltipItem, data) {
                                    var dataset = data['datasets'][0];
                                    var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                                    return '' + percent + '%';
                                }
                            },
                            backgroundColor: '#FFF',
                            titleFontSize: 16,
                            titleFontColor: '#0066ff',
                            bodyFontColor: '#000',
                            bodyFontSize: 14,
                            displayColors: false,
                        }
                    }}
                    data={{
                        labels: [
                            'Desktop',
                            'Mobile',
                        ],
                        datasets: devicesDataSet
                    }} />
                <div class="analytics-pi-chart" contextmenu="none">
                    <p>{totalDevices}</p>
                    <span>total</span>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsData

import { Parser } from 'json2csv';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/common';
import { withFirebase } from '../../components/Firebase';
import DashboardLayout from '../Dashboard';
import AnalyticsCards from './AnalyticsCards';
import AnalyticsChart from './AnalyticsChart';
import AnalyticsData from './AnalyticsData';
import AnalyticsSocial from './AnalyticsSocial';
import AnalyticsViewed from './AnalyticsViewed';
import { calculateCounts } from "./functions";
import _ from "lodash"
import "./style.css";
import { toaster } from 'evergreen-ui';

const datasetOptions = {
    label: "sessions",
    fill: false,
    backgroundColor: "rgba(155,163,227,.5)",
    borderColor: "#2234c9",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "#2234c9",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "#F06878",
    pointHoverBorderColor: "#F06878",
    pointHoverBorderWidth: 2,
    pointRadius: 5,
    pointHitRadius: 5,
}

function Analytics(props) {
    const { authUser, firebase } = props;
    const [isLoading, setIsLoading] = useState(false)
    const [chartData, setChartData] = useState(null)
    const [participants, setParticipants] = useState(null)
    const [participantsSize, setParticipantsSize] = useState(0)
    const [photos, setPhotos] = useState(0)
    const [views, setViews] = useState(0)
    const [Leads, setLeads] = useState({})
    const [TotalDownloads, setTotalDownloads] = useState(0)
    const [Devices, setDevices] = useState({
        mobile: 0,
        desktop: 0
    })
    const [totalShare, setTotalShare] = useState({
        fb: 0,
        ig: 0,
        tw: 0,
    })
    useEffect(() => {
        if (firebase && authUser) {
            getAnalyticsData();
        }
        return () => {

        }
    }, [firebase]);

    useEffect(() => {
        setChartData(null)
        if (participants && participants.length > 0) {

            let data = []

            participants.sort((a, b) => {
                const x = new Date(a.data.localDate).getTime();
                const y = new Date(b.data.localDate).getTime();
                return x - y;
            }
            )

            participants.forEach((p, index) => {
                let currentData = data.filter(d => {
                    return d.date === moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM YY")
                })[0]

                if (currentData) {
                    currentData.value += 1
                    data.splice(_.findIndex(data, { date: moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM YY") }), 1, currentData)
                } else {
                    currentData = {
                        date: moment(p.data.localDate, "YYYY-MM-DD hh:mm a").format("D MMMM YY"),
                        value: 1,
                    }
                    data = data.concat(currentData)
                }
            })

            let dataForChart = {
                labels: data.map(d => d.date),
                data: data.map(d => d.value),
                datasets: [
                    {
                        ...datasetOptions,
                        data: data.map(d => d.value),
                    },
                ],
            }


            setChartData(dataForChart)
        }
    }, [participants])

    const getAnalyticsData = async () => {
        setIsLoading(true);
        let analyticsSnapshot = await firebase.db
            .collection("analytics")
            .where("accountID", "==", authUser.uid)
            .get()
            .catch((error) => {
                console.log("Error getting documents: ", error)
            })

        let galleriesSnapshot = await firebase.db
            .collection("photos")
            .where("accountID", "==", authUser.uid)
            .get()
            .catch((error) => {
                console.log("Error getting documents: ", error)
            })

        let analytics = []
        analyticsSnapshot.docs.map(doc => {
            analytics = analytics.concat({
                id: doc.id,
                data: {
                    ...doc.data(),
                },
            })
        })
        setParticipants(analytics);
        setParticipantsSize(analytics.length);
        calculateLeads(analytics);

        let Photos = []
        galleriesSnapshot.docs.map(doc => {
            Photos = Photos.concat({
                id: doc.id,
                data: {
                    ...doc.data(),
                },
            })
        })
        setPhotos(Photos.length);

        const photoViewCounterRef = []
        const downloadCounterRef = []
        const fbCounterRef = []
        const instaCounterRef = []
        const twitterCounterRef = []
        Photos.forEach(async data => {
            photoViewCounterRef.push(firebase.db.collection(`photos/${data.id}/views`).get())
            downloadCounterRef.push(firebase.db.collection(`photos/${data.id}/downloads`).get())
            fbCounterRef.push(firebase.db.collection(`photos/${data.id}/facebookShare`).get())
            instaCounterRef.push(firebase.db.collection(`photos/${data.id}/instagramShare`).get())
            twitterCounterRef.push(firebase.db.collection(`photos/${data.id}/twitterShare`).get())
        })

        const viewsCountArray = await Promise.all(photoViewCounterRef)
        const TotalView = calculateCounts(viewsCountArray);
        const facebookShareCounter = await Promise.all(fbCounterRef)
        const fbCounter = calculateCounts(facebookShareCounter)

        const instaCounterArrayRef = await Promise.all(instaCounterRef)
        const instaCounter = calculateCounts(instaCounterArrayRef)

        const twitterCounterArrayRef = await Promise.all(twitterCounterRef)
        const twitterCounter = calculateCounts(twitterCounterArrayRef)
        setTotalShare({
            fb: fbCounter,
            ig: instaCounter,
            tw: twitterCounter
        })
        const devices = calculateDevices(viewsCountArray)
        setDevices({
            mobile: devices.Android + devices.iOS,
            desktop: devices.Desktop
        })
        const downloadCounter = await Promise.all(downloadCounterRef)
        const totalDownloads = calculateCounts(downloadCounter)
        setTotalDownloads(totalDownloads);
        setViews(TotalView);
        setIsLoading(false);
    }

    const calculateDevices = (viewsCountArray) => {
        return viewsCountArray.map(data => {
            return data.docs.filter(doc => {
                return true
            })
        }).reduce((arr, data) => {
            return arr.concat(data.map(d => d.data()))
        }, []).reduce((map, obj) => {
            if (map.hasOwnProperty(obj.deviceType)) {
                map[obj.deviceType] = map[obj.deviceType] + 1
            } else {
                map[obj.deviceType] = 1
            }
            return map
        }, {})
    }

    const calculateLeads = (analytics) => {

        //Not sure, but I think these data points are dynamic and should not be hardcoded (may need to refactor later)
        const emailAddresses = analytics.filter(a => a.data.emailAddress && a.data.emailAddress.length > 0).map(a => a.data.emailAddress).length
        const phoneNumbers = analytics.filter(a => a.data.phoneNumber && a.data.phoneNumber.length > 0).map(a => a.data.phoneNumber).length
        const firstName = analytics.filter(a => a.data.firstName && a.data.firstName.length > 0).map(a => a.data.firstName).length
        const lastName = analytics.filter(a => a.data.lastName && a.data.lastName.length > 0).map(a => a.data.lastName).length
        const zipCode = analytics.filter(a => a.data.zipCode && a.data.zipCode.length > 0).map(a => a.data.zipCode).length
        const birthDate = analytics.filter(a => a.data.birthDate && a.data.birthDate.length > 0).map(a => a.data.birthDate).length
        const totalLeads = emailAddresses + phoneNumbers + firstName + lastName + zipCode + birthDate

        setLeads({
            emailAddresses: emailAddresses,
            phoneNumbers: phoneNumbers,
            firstNames: firstName,
            lastNames: lastName,
            zipCodes: zipCode,
            birthDates: birthDate,
            totalLeads: totalLeads,
        })
    }

    const capitalize = (s) => {
        if (typeof s !== "string") return ""
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const downloadCSV = () => {
        if (participants.length > 0) {
            toaster.notify('Analytics report downloading.')
            let fileName = "analytics"
            const json2csvParser = new Parser()
            const csv = json2csvParser.parse(participants.map(p => {
                let updatedDate = {}
                const data = p.data
                for (let key in data) {
                    updatedDate[capitalize(key)] = data[key]
                }
                return updatedDate
            }))

            const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv)
            const link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", `${fileName}.csv`)
            document.body.appendChild(link) // Required for FF

            link.click()
        } else {
            alert("There is no data to download")
        }

    }
    return (
        <DashboardLayout>
            {isLoading ? <Loading /> : null}
            <div className="ds-title">
                <div className="ds-container">
                    <div className="title">
                        <h1>Analytics</h1>
                    </div>
                </div>
            </div>

            <div className="ds-container">
                <section className="analytics">
                    <div className="sub-title">
                        <h3>Overview</h3>
                    </div>
                    <AnalyticsCards
                        authUser={authUser}
                        participants={participantsSize}
                        views={views}
                        photos={photos}
                    />

                    <div className="sub-title">
                        <h3>Sessions</h3>
                    </div>
                    <AnalyticsChart
                        authUser={authUser}
                        data={chartData}
                    />

                    <div className="sub-title data-sub-title">
                        <h3>Data Capture Leads</h3>
                        <h3 className="data-devices-sub-title">Devices</h3>
                    </div>
                    <AnalyticsData
                        authUser={authUser}
                        leads={Leads}
                        devicesData={Devices}
                        downloadCSV={downloadCSV} />

                    <div className="sub-title">
                        <h3>Social</h3>
                    </div>
                    <AnalyticsSocial
                        authUser={authUser}
                        TotalDownloads={TotalDownloads}
                        fb={totalShare.fb}
                        ig={totalShare.ig}
                        tw={totalShare.tw} />

                    <div className="sub-title">
                        <h3>Most Viewed</h3>
                    </div>
                    <AnalyticsViewed authUser={authUser} />

                </section>
            </div>
        </DashboardLayout>
    )
}

export default withFirebase(Analytics)

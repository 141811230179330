import React, { useEffect, useState } from 'react'
import { Card } from '../../components/common';
import { withFirebase } from '../../components/Firebase';
const ico_photos = require("../../assets/images/svgs/ico_photos.svg")
const ico_views = require("../../assets/images/svgs/ico_views.svg")

function AnalyticsCards(props) {
    const { firebase, authUser, participants, photos, views } = props;

    return (
        <div className="ds-row analytics-row">
            <Card
                className="analytics-card"
                number={participants}
                title="TOTAL PARTICIPANTS" />
            <Card
                className="analytics-card"
                number={photos}
                title="TOTAL PHOTOS"
                icon={ico_photos} />
            <Card
                className="analytics-card"
                number={views}
                title="TOTAL VIEWS"
                icon={ico_views} />
        </div>
    )
}

export default withFirebase(AnalyticsCards);
